<template>
  <div v-if="bundle?.bundleProducts" class="block-bundle-spotlight">
    <div class="block-bundle-spotlight__inner">
      <AtomHeading
        v-if="bundleTitle"
        appereance="h2"
        tag="h2"
        :text="bundleTitle"
        class="block-bundle-spotlight__title"
      />

      <AtomRichtext
        v-if="bundleIntro"
        class="is-left"
        :string="bundleIntro"
      />

      <div v-if="bundle" class="block-bundle-spotlight__content">
        <AtomFlag
          class="block-bundle-spotlight__flag"
          :text="bundleFlag"
        />
        <div class="block-bundle-spotlight__products">
          <ShopProductSlider
            :products="bundle.bundleProducts"
          />
        </div>

        <div class="block-bundle-spotlight__info">
          <div class="bundle-spotlight-info__cta">
            <div class="bundle-spotlight-info__cta-inner">
              <div class="bundle-spotlight-info__price">
                {{ formatNumberToCurrency(bundle.price) }}.–
              </div>
              <div v-if="bundleLabel" class="bundle-spotlight-info__name">
                {{ bundleLabel }}
              </div>
              <div class="bundle-spotlight-info__link">
                <AtomCta
                  :link="`../produkte/${bundleLink}`"
                  :text="
                    useTranslation(
                      'general',
                      'go_to_bundle',
                    )
                  "
                />
              </div>
            </div>
          </div>

          <ClientOnly>
            <ShopProductControls
              :has-bookmark-icon="true"
              :has-calendar-icon="false"
              :has-loved-icon="false"
              :has-helped-icon="false"
              :has-cart-icon="true"
            />
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
    Setup
*/
const context = useContextStore();

/*
    Fetch Bundle
*/
const { findOne } = useStrapi4();
const bundle = ref(null);

const fetchBundle = async () => {
    let collectionBundle = null;

    if (props.data?.strapi_data_key) {
        const collection = context.getCollection;
        const collectionId = context.getId;
        const collectionSingular = collection.split('.')[1];
        const collectionPlural = singularToPlural(collectionSingular);
        const collectionEntry = await findOne(collectionPlural, collectionId, {
            populate: [props.data.strapi_data_key],
        });
        collectionBundle = collectionEntry?.data?.attributes[props.data.strapi_data_key];
    }

    const id = collectionBundle?.data?.attributes?.slug || props.data?.bundle;

    if (!id) return;

    try {
        const data = await findOne('shop-items', id);
        bundle.value = data;
    } catch (e) {
        useSentryError(e);
    }
};

useLazyAsyncData(`bundle-spotlight/${getUid()}`, fetchBundle, {});

/*
    Prepare Title
*/
const bundleTitle = computed(() => (props.data?.bundle_name
    ? props.data?.bundle_name : bundle.value?.title));

/*
    Prepare Intro Text
*/
const bundleIntro = computed(() => (props.data?.bundle_intro
    ? props.data?.bundle_intro : bundle.value?.bundleIntro));

/*
    Prepare Label Text
*/
const bundleLabel = computed(() => (props.data?.bundle_label
    ? props.data?.bundle_label : bundle.value?.name));

/*
    Prepare Link
*/
const bundleLink = computed(() => slugify(bundle.value?.slug));

/*
    Prepare Bundleflag
*/
const bundleFlag = computed(
    () => `${
        bundle.value.bundleProducts.data?.length
            ? bundle.value.bundleProducts.data.length
            : bundle.value.bundleProducts.length
    }
      ${useTranslation('general', 'products_in_bundle')}`,
);

/*
    For storyblok only: refetch bundle if select field value changes
*/
const route = useRoute();
if (route.query._storyblok) {
    watch(
        () => props.data.bundle,
        (newValue) => {
            fetchBundle(newValue);
        },
    );
}

/*
    Provide Values
*/
provide('item', bundle);
provide('is-popup-disabled', false);
</script>

<style lang="scss">
.block-bundle-spotlight {
    @include grid-full;
    @include fluid('padding-bottom', 60px, 80px);
    @include fluid('margin-bottom', 25px, 25px);
    @include fluid('border-top-left-radius', 40px, 80px);

    padding-top: 60px;
    background: $C_GREEN_BRIGHT;

    .atom-richtext {
        @include default-content-columns;
        @include fluid('margin-bottom', 30px, 60px);
    }

    & + .atom-heading {
        @include fluid('margin-top', 30px, 60px);
    }

    & + .shop-products {
        @include fluid('margin-top', 30px, 60px);
    }

    & + .block-quote {
        @include fluid('margin-top', 30px, 60px);
    }
}

.block-bundle-spotlight__inner {
    @include grid-layout();

    width: 100%;
    max-width: var(--grid-width);
    padding: 0 var(--page-padding);
    margin: 0 auto;
}

.block-bundle-spotlight__title {
    @include grid-columns(1, var(--grid-columns));
    @include fluid('margin-bottom', 10px, 40px);
}

.block-bundle-spotlight__content {
    @include grid-columns(1, var(--grid-columns));

    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;
    @include fluid('padding-left', 20px, 53px);
    border: 4px solid $C_PRIMARY;
    border-radius: 8px;
}

.block-bundle-spotlight__products {
    width: 71%;

    @include mobile {
        margin-bottom: 20px;
    }
}

.block-bundle-spotlight__info {
    @include fluid('padding-left', 23px, 88px);
    @include typo-size('p');
    @include typo-font('bold');

    display: flex;
    width: 29%;
    flex-direction: column;

    @include mobile {
        padding-left: 0px;
    }
}

.bundle-spotlight-info__cta {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    @include mobile {
        margin-bottom: 40px;
    }
}

.bundle-spotlight-info__price {
    @include typo-font('light');

    margin-bottom: 2px;
    color: $C_GREEN_DARK;
}

.bundle-spotlight-info__name {
    @include typo-font('regular');

    margin-bottom: 10px;
    color: $C_GREEN_DARK;
}

.block-bundle-spotlight__products,
.block-bundle-spotlight__info {
    @include mobile {
        width: 100%;
    }
}

.block-bundle-spotlight__flag {
    position: absolute;
    top: 0;
    right: 0;
}
</style>
